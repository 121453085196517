import { styled } from '@mui/material/styles';
import { Container, Typography, Grid, Stack } from '@mui/material';
import Page from '../../components/Page';
import { ComingSoonIllustration } from '../../assets';
import { useTranslations } from 'hooks/useTranslations';

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: theme.spacing(10),
}));

export default function TravelCancelationComingSoon() {
  const translations = useTranslations();

  return (
    <RootStyle title="Скоро Будет | TourAgent Club">
      <Container>
        <Grid container>
          <Grid item md={6} sm={12} sx={{ justifyContent: 'center', display: { xs: 'flex', md: 'none' } }}>
            <ComingSoonIllustration sx={{ mt: 3, mb: 5, height: 240 }} />
          </Grid>

          <Grid item md={6} sx={{ px: 2 }}>
            <Typography variant="h3" paragraph>
              {translations.comingSoon.title()}
            </Typography>
            <Typography sx={{ color: '#444', mt: 3, mb: 0.5 }}>
              В данный момент идет последний этап интеграции нового продукта.
            </Typography>
            <Typography sx={{ color: '#444', mb: 1 }}>
              Для Вас готовится страховка от невыезда со следующими условиями:
            </Typography>
            <Stack spacing={0.5} sx={{ color: '#444', mb: 2, mt: 1.5 }}>
              <li>3% от стоимости тура</li>
              <li>покрытие рисков COVID-19</li>
              <li>амбулаторное лечение туриста и его близких родственников </li>
              <li>не требуется минимизация от туроператора</li>
              <li>выплата в течение 10 дней после предоставления комплекта документов </li>
              <b style={{ color: '#444', letterSpacing: 0.2, marginTop: 24 }}>Комиссия для наших агентов - 30%!</b>
            </Stack>
          </Grid>

          <Grid item md={6} sx={{ justifyContent: 'center', display: { sm: 'none', md: 'flex' } }}>
            <ComingSoonIllustration sx={{ mt: 6, height: 240 }} />
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
